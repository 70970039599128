<template>
  <div class="article-list" ref="article-list">
    <!-- List 组件通过 loading 和 finished 两个变量控制加载状态，
    当组件初始化或滚动到到底部时，会触发 load 事件并将 loading 设置成 true，此时可以发起异步操作并更新数据，数据更新完毕后，将 loading 设置成 false 即可。
    若数据已全部加载完毕，则直接将 finished 设置成 true 即可。 -->
    <!-- load 事件： 
    List 初始化后会触发一次 load 事件，用于加载第一屏的数据。
    如果一次请求加载的数据条数较少，导致列表内容无法铺满当前屏幕，List 会继续触发 load 事件，直到内容铺满屏幕或数据全部加载完成。
    loading 属性：控制加载中的 loading 状态 
    非加载中，loading 为 false，此时会根据列表滚动位置判断是否触发 load 事件（列表内容不足一屏幕时，会直接触发）
    加载中，loading 为 true，表示正在发送异步请求，此时不会触发 load 事件
    finished 属性：控制加载结束的状态 
    在每次请求完毕后，需要手动将 loading 设置为 false，表示本次加载结束
    所有数据加载结束，finished 为 true，此时不会触发 load 事件 -->
    <van-pull-refresh 
        v-model="isOnRefreshLoading" 
        :success-text="refreshSuccessText"
        :success-duration="1500"
        @refresh="onRefresh"
        > 
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
            <!-- <van-cell 
                v-for="(article, index) in articles" 
                :key="index" 
                :title="article.title" 
            /> -->
            <ArticleItem
                v-for="(article, index) in articles" 
                :key="index"
                :article="article" 
                >
            </ArticleItem>
        </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getArticles } from '@/api/article'
import ArticleItem from '@/components/articleitem'
import { debounce } from 'lodash'

export default {
  name: 'ArticleList',
  components: {
      ArticleItem
  },
  props: {
      channel: {
          type: Object,
          require: true
      }
  },
  data () {
    return {
        articles: [], // 数据列表
        loading: false,  // 加载数据
        finished: false, // 加载结束
        timestamp: null,  // 获取下一页的时间戳
        isOnRefreshLoading: false, // 向下拉刷新状态
        refreshSuccessText: '', // 刷新成功提示
        scrollTop: 0 // 列表滚动到顶部的距离
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
      const articleList = this.$refs['article-list']
      articleList.onscroll = debounce(() => {
          this.scrollTop = articleList.scrollTop
      },50)
  },
  activated() {
      // 组件重新激活执行，把记录到顶部的距离重新设置回去
      this.$refs['article-list'].scrollTop = this.scrollTop
  },
  deactivated() {

  },
  methods: {
    async onLoad() {
        // 1.请求获取数据
        const { data } = await getArticles({
            channel_id: this.channel.id,  // 频道 ID
            // timestamp 相当于页码，请求最新数据当前5最新时间戳，下一页数据使用上一次返回数据中的时间戳
            timestamp: this.timestamp || Date.now(),  // 时间戳，请求新的推荐数据传当前的时间戳，请求历史推荐传指定的时间戳
            with_top: 1   // 是否包含置顶，进入页面第一次请求时要包含置顶文章，1-包含置顶，0-不包含
        })
        
        // 2.把数据放到 List 数组中
        const { results } = data.data
        this.articles.push(...results)
        
        // 3.设置本次加载状态结束，判断是否加载下一次，否则永远停在这里
        this.loading = false

        // 4.数据全部加载完成
        if(results.length) {
            // 更新获取下一页数据的页码
            this.timestamp = data.data.pre_timestamp
        } else {
            // 没有数据了，把加载状态设置结束，不再触发加载更多
            this.finished = true
        }
    },

    async onRefresh() {
        // 1.请求获取数据
        const { data } = await getArticles({
            channel_id: this.channel.id,  // 频道 ID
            // timestamp 相当于页码，请求最新数据当前5最新时间戳，下一页数据使用上一次返回数据中的时间戳
            timestamp: Date.now(),  // 时间戳，请求新的推荐数据传当前的时间戳，请求历史推荐传指定的时间戳
            with_top: 1   // 是否包含置顶，进入页面第一次请求时要包含置顶文章，1-包含置顶，0-不包含
        })

         // 2.把数据放到 List 数组头部中（top)
        const { results } = data.data
        this.articles.unshift(...results)

        // 3.关闭刷新状态
        this.isOnRefreshLoading = false

        // 4.刷新成功的提示
        this.refreshSuccessText = `更新了${results.length}条数据`
    }
  }
}
</script>

<style scoped lang="less">
.article-list {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 50px;
    top: 90px;
    overflow-y: auto;
}
</style>